@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: 'Orbitron', sans-serif; Updated to use Orbitron Light */
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Orbitron', monospace; /* Optional: Use Orbitron for code as well, or choose another monospace font */
}
